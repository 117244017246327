import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/services/Hero";
import CTA from "../sections/services/CTA";
import ConsultingContent from '../sections/services/ConsultingContent';
import StaffingContent from '../sections/services/StaffingContent';
import TrainingContent from '../sections/services/TrainingContent';

const ServicesPage = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          footerStyle: 'style4'
        }}>
        <Hero />
        <TrainingContent className='bg-default-4 pt-6 pt-lg-14 pb-10 pb-lg-10  overflow-hidden position-relative' />
        <ConsultingContent className='bg-default-6 pt-6 pt-md-10 pt-lg-10 pb-6 pb-md-10 pb-lg-13 overflow-hidden position-relative' />
        <StaffingContent className='pt-6 pt-md-10 pt-lg-14 pb-6 pb-md-10 pb-lg-13 overflow-hidden position-relative' />
        <CTA className='bg-images' />
      </PageWrapper>
    </>
  );
};
export default ServicesPage;
